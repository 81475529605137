import { BUILD_ID } from "../analytics";
import { mapToObject, typedEntries } from "../utils/objects";
import { AccountsDomain } from "./Accounts";
import { ReclaimWS } from "./adaptors/ws";
import { AnalyticsDomain } from "./Analytics";
import { ApiManagementDomain } from "./ApiManagement";
import { CalendarsDomain } from "./Calendars";
import { CalendarSyncPolicyDomain } from "./CalendarSyncPolicy";
import { ReclaimApi } from "./client";
import { CredentialsDomain } from "./Credentials";
import { EventsDomain } from "./Events";
import { HabitsDomain } from "./Habits";
import { InsightsDomain } from "./Insights";
import { IntegrationsDomain } from "./Integrations";
import { MomentDomain } from "./Moment";
import { OneOnOnesDomain } from "./OneOnOnes";
import { PeopleDomain } from "./People";
import { PlannerDomain } from "./Planner";
import { ResourcesDomain } from "./Resources";
import { SchedulingLinksDomain } from "./scheduling-links/SchedulingLinks";
import { SlackDomain } from "./Slack";
import { TasksDomain } from "./Tasks";
import { TeamDomain } from "./team/Team";
import { TimeSchemesDomain } from "./TimeSchemes";
import { Domain } from "./types";
import { UsersDomain } from "./Users";
import { WeeklyReportsDomain } from "./WeeklyReports";

const API_BASE_URI = process.env.NEXT_PUBLIC_API_BASE_URI;

const SHADOW_TRACKING_CODE_QS_NAME = "shadowTrackingCode";
const SHADOW_API_KEY_QS_NAME = "shadowApiKey";

let shadowApiKey: string | undefined;
let shadowTrackingCode: string | undefined;

/**
 * This is all to remove the shadow/impersonation stuff
 * from the query string.  Dropping it in an anonymous
 * function to make it easier to visually parse out, and
 * to avoid collision with the many common variable
 * names it uses.
 */
(() => {
  if (typeof window !== "object") return;

  const url = new URL(window.location.href);
  // get the query string as an object
  const qObj = mapToObject(url.search.replace(/^\?/, "").split("&"), (pair) => {
    const [key, val] = pair.split("=");
    return [key, decodeURIComponent(val)];
  });

  // store the props
  shadowTrackingCode = qObj[SHADOW_TRACKING_CODE_QS_NAME];
  shadowApiKey = qObj[SHADOW_API_KEY_QS_NAME];

  // remove the shadow props from the URL
  delete qObj[SHADOW_TRACKING_CODE_QS_NAME];
  delete qObj[SHADOW_API_KEY_QS_NAME];

  // get the remaining query string
  const qArr = typedEntries(qObj)
    .map(([key, val]) => key && val && `${key}=${val}`)
    .filter((i) => !!i);

  // reattach it to the URL
  url.search = qArr.length ? "?" + qArr.join("&") : "";

  //set the URL back in the browser
  void window.history.replaceState(null, "", url);
})();

export const LOCAL_TIME_FORMAT = "HH:mm:ss";
export abstract class DomainsConstructor {
  users: UsersDomain;
  accounts: AccountsDomain;
  credentials: CredentialsDomain;
  calendars: CalendarsDomain;
  calendarSyncPolicies: CalendarSyncPolicyDomain;
  events: EventsDomain;
  habits: HabitsDomain;
  tasks: TasksDomain;
  insights: InsightsDomain;
  analytics: AnalyticsDomain;
  people: PeopleDomain;
  slack: SlackDomain;
  oneOnOnes: OneOnOnesDomain;
  weeklyReports: WeeklyReportsDomain;
  integrations: IntegrationsDomain;
  planner: PlannerDomain;
  moment: MomentDomain;
  team: TeamDomain;
  apiManagement: ApiManagementDomain;
  schedulingLinks: SchedulingLinksDomain;
  timeSchemes: TimeSchemesDomain;
  resources: ResourcesDomain;
}

export class Client extends DomainsConstructor {
  private static DomainClasses = {
    users: UsersDomain,
    accounts: AccountsDomain,
    credentials: CredentialsDomain,
    calendars: CalendarsDomain,
    calendarSyncPolicies: CalendarSyncPolicyDomain,
    events: EventsDomain,
    habits: HabitsDomain,
    tasks: TasksDomain,
    insights: InsightsDomain,
    analytics: AnalyticsDomain,
    people: PeopleDomain,
    slack: SlackDomain,
    oneOnOnes: OneOnOnesDomain,
    weeklyReports: WeeklyReportsDomain,
    integrations: IntegrationsDomain,
    planner: PlannerDomain,
    moment: MomentDomain,
    team: TeamDomain,
    apiManagement: ApiManagementDomain,
    schedulingLinks: SchedulingLinksDomain,
    timeSchemes: TimeSchemesDomain,
    resources: ResourcesDomain,
  };

  private domains: Record<string | number | symbol, Domain> = {};
  private proxy: Client;
  public client: ReclaimApi;
  public ws: ReclaimWS;

  constructor();
  constructor(baseUrl: string);
  constructor(client: ReclaimApi);
  constructor(config?: ReclaimApi | string) {
    super();

    if (config instanceof ReclaimApi) {
      this.client = config;
    } else {
      this.client = new ReclaimApi({
        baseUrl: config || API_BASE_URI,
        BUILD_ID,
        baseApiParams:
          shadowApiKey && shadowTrackingCode
            ? {
                headers: {
                  "Content-Type": "application/json",
                  Referrer: "no-referrer",
                  Authorization: `Bearer ${shadowApiKey}`,
                  "X-Reclaim-Shadow": shadowTrackingCode,
                },
              }
            : undefined,
      });
    }

    if (typeof WebSocket !== "undefined") this.ws = new ReclaimWS();

    this.proxy = new Proxy(this, {
      get: function (target, key: keyof Client) {
        if (key === "client") return target.client;
        if (Client.DomainClasses[key]) {
          return target.getDomain(key as keyof DomainsConstructor);
        }
      },
    });

    return this.proxy;
  }

  private getDomain(key: keyof DomainsConstructor) {
    if (!this.domains[key] && !Client.DomainClasses[key]) return;
    if (!this.domains[key]) {
      const DomainClass = Client.DomainClasses[key];
      this.domains[key] = new DomainClass(key, this.client, this.proxy, this.ws);
    }
    return this.domains[key];
  }
}

export const reclaim: Client = new Client();
